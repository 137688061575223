<template>
  <div>
    <h3 class="title-g">
      <div>发票记录</div>
      <div>
        <span>未开发票：{{ invoiceBizTotal }} 元</span>
        <span style="margin-left: 40px"
          >待收发票：{{ invoiceStoreTotal }} 元</span
        >
      </div>
    </h3>
    <el-tabs v-model="activeName" @click="changeActive">
      <el-tab-pane label="商家" name="biz"> </el-tab-pane>
      <el-tab-pane label="洗车行" name="store"> </el-tab-pane>
    </el-tabs>
    <table>
      <thead>
        <td v-for="(value, key) in tableList" :key="key">
          {{ value }}
        </td>
      </thead>
      <tr v-for="(item, index) in invoiceHistory" :key="index">
        <td v-for="(value, key) in tableList" :key="key">
          {{ item[key] }}
        </td>
      </tr>
    </table>
    <br />
    <el-pagination
      :page-size="15"
      :pager-count="9"
      :current-page.sync="pageNumber"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getAdminInvoiceList } from "@/api/api";
export default {
  //发票统计：
  data() {
    return {
      activeName: "biz",
      tableList: {
        invoiceSn: "流水号",
        state: "状态",
        custId: "客户ID",
        custName: "客户名称",
        custType: "客户类别",
        amountInvoice: "发票金额",
        remark: "发票编号",
        operator: "操作人",
        genTime: "生成时间",
      },
      pageNumber: 1,
      total: 21,
      invoiceBizTotal: "", // accountHistory type=biz 的所有转出 - refund type<2 的total -invoicehistory type=biz 的total
      invoiceStoreTotal: "", //accountHistory type=store 的所有转出 - invoiceHistory type=store 的total
      invoiceHistory: [
        
      ],
    };
  },
  watch: {
    activeName() {
      //普通的watch监听
      this.pageNumber = 1;
      this.getAdminInvoiceListFn();
    },
  },
  mounted() {
    this.getAdminInvoiceListFn();
  },
  methods: {
    changeActive() {
      this.getAdminInvoiceListFn();
    },
    getAdminInvoiceListFn() {
      const that = this;
      getAdminInvoiceList({
        custType: that.activeName,
        page: that.pageNumber,
      }).then((res) => {
        if (res.result) {
          that.invoiceHistory = res.data.invoice_hisotry;
          that.invoiceBizTotal = res.data.invoiceBizTotal;
          that.invoiceStoreTotal = res.data.invoiceStoreTotal;
          that.total = res.data.total;
        }
        
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getAdminInvoiceListFn();
    },
  },
};
</script>

<style scoped>
.list-g div {
  min-width: 300px;
  font-size: 16px;
  margin: 20px 0px;
}
</style>